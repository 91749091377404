<template>
  <div class="page_content page_content_mamber">
    <div class="content_header d-flex align-items-center">
      <span>會員管理</span>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="search_section">
          <template v-if="member_content !== 'register'">
            <div class="form-group d-flex align-items-center">
              <label>搜尋模式</label>
              <div class="form-check form-check-inline">
                <input
                  id="search_mode_keyword"
                  v-model="search.mode"
                  class="form-check-input"
                  type="radio"
                  :value="1"
                  @change="switch_searchmode()"
                >
                <label
                  class="form-check-label"
                  for="search_mode_keyword"
                >關鍵字</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  id="search_mode_register"
                  v-model="search.mode"
                  class="form-check-input"
                  type="radio"
                  :value="2"
                  @change="switch_searchmode()"
                >
                <label
                  class="form-check-label"
                  for="search_mode_register"
                >註冊日期</label>
              </div>
            </div>
            <div
              v-if="search.mode === 1"
              class="form-group d-flex align-items-center"
            >
              <label for="search_value">關鍵字</label>
              <input
                id="search_value"
                v-model="search.value"
                v-validate="'required'"
                type="text"
                name="關鍵字"
                class="form-control form_input"
                :class="{'is-invalid': errors.has('search.關鍵字')}"
                data-vv-scope="search"
                placeholder="姓名、手機"
              >
            </div>
            <div
              v-if="search.mode === 2"
              class="form-group d-flex align-items-center"
            >
              <label for="saerch_register">註冊日期</label>
              <input
                id="saerch_register"
                v-model="search.value"
                v-validate="'required'"
                type="date"
                name="註冊日期"
                class="form-control form_input"
                :class="{'is-invalid': errors.has('search.註冊日期')}"
                data-vv-scope="search"
                placeholder="選擇日期"
              >
            </div>
            <a
              href="#"
              class="search_btn d-flex align-items-center justify-content-center"
              @click.prevent="get_members()"
            >
              <span>搜尋</span>
            </a>
            <a
              href="#"
              class="search_btn d-flex align-items-center justify-content-center ml-2 px-2"
              @click.prevent="member_content = 'register', init_validate()"
            >
              <span>會員註冊</span>
            </a>
          </template>
          <a
            v-if="member_content === 'detail' || member_content === 'register'"
            href="#"
            class="search_btn btn btn_outline_primary d-flex align-items-center justify-content-center ml-auto px-2"
            @click.prevent="member_content = 'list', switch_nav('', false)"
          >返回</a>
        </div>
        <ul
          v-if="member_content === 'detail'"
          class="nav nav-tabs"
        >
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ 'active': nav_tabs === 'member_info' }"
              @click.prevent="switch_nav('member_info', false)"
            >會員資料</a>
          </li>
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ 'active': nav_tabs === 'order_list' }"
              @click.prevent="switch_nav('order_list', false)"
            >訂單列表</a>
          </li>
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ 'active': nav_tabs === 'receipt' }"
              @click.prevent="switch_nav('receipt', false)"
            >發票設定</a>
          </li>
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ 'active': nav_tabs === 'coupon' }"
              @click.prevent="switch_nav('coupon', false)"
            >折扣碼</a>
          </li>
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ 'active': nav_tabs === 'point_account' }"
              @click.prevent="switch_nav('point_account', false)"
            >購物金</a>
          </li>
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ 'active': nav_tabs === 'log_point' }"
              @click.prevent="switch_nav('log_point', false)"
            >點數紀錄</a>
          </li>
        </ul>
        <div
          v-if="members.length > 0 && member_content === 'list'"
          class="content_body"
        >
          <div class="table-responsive">
            <table class="table table-borderless">
              <thead class="">
                <tr>
                  <th
                    scope="col"
                    width="100px"
                    class="text-color_primary"
                  >
                    會員姓名
                  </th>
                  <th
                    scope="col"
                    width="100px"
                    class="text-color_primary"
                  >
                    手機
                  </th>
                  <th
                    scope="col"
                    width="80px"
                    class="text-color_primary"
                  >
                    下單
                  </th>
                  <th
                    scope="col"
                    width="100px"
                    class="text-color_primary"
                  >
                    累計消費
                  </th>
                  <th
                    scope="col"
                    width="150px"
                    class="text-color_primary"
                  >
                    購物金
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    住址
                  </th>
                  <th
                    scope="col"
                    width="100px"
                    class="text-color_primary"
                  >
                    VIP日期
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    來源
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="member_detail in members"
                  :key="member_detail.userId"
                  :class="{ 'table_disabled': !member_detail.status }"
                >
                  <td>{{ member_detail.fullname }}</td>
                  <td>
                    <a
                      href="#"
                      class="text-color_primary"
                      @click.prevent="get_member_detail(member_detail.userId), nav_tabs = 'member_info'"
                    >
                      {{ member_detail.celnum }}
                    </a>
                  </td>
                  <td>
                    <a
                      v-if="member_detail.status"
                      href="#"
                      class="d-inline-block btn btn_outline_primary px-2 rounded-sm"
                      @click.prevent="to_order(member_detail.userId)"
                    >
                      下單
                    </a>
                  </td>
                  <td>{{ member_detail.amountTotal | currency }}</td>
                  <td>{{ member_detail.amountPoint | currency }}</td>
                  <td>{{ member_detail.address }}</td>
                  <td>{{ member_detail.dtVip }}</td>
                  <td>
                    <div>{{ member_detail.regSystemCode }}</div>
                    <div>{{ member_detail.regPlatformCode }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          v-if="member_content === 'register'"
          class="content_body"
        >
          <div class="member_section">
            <div class="d-flex mb-3">
              <span class="section_title">基本資料</span>
            </div>
            <div class="member_basic_item">
              <div class="form-group d-flex align-items-center">
                <label for="register_fullname">會員姓名</label>
                <input
                  id="register_fullname"
                  v-model="register.fullname"
                  v-validate="'required'"
                  type="text"
                  name="會員姓名"
                  class="form-control form_input"
                  :class="{'is-invalid': errors.has('register.會員姓名')}"
                  data-vv-scope="register"
                  placeholder="會員姓名"
                >
              </div>
            </div>
            <div class="member_basic_item">
              <div class="form-group d-flex align-items-center">
                <label for="register_celnum">手機</label>
                <input
                  id="register_celnum"
                  v-model="register.celnum"
                  v-validate="'required'"
                  type="tel"
                  name="手機"
                  class="form-control form_input"
                  :class="{'is-invalid': errors.has('register.手機')}"
                  data-vv-scope="register"
                  placeholder="手機"
                >
              </div>
            </div>
            <div class="member_basic_item">
              <div class="form-group d-flex align-items-center">
                <label for="register_password">密碼</label>
                <input
                  id="register_password"
                  v-model="register.password"
                  v-validate="'required'"
                  type="text"
                  name="密碼"
                  class="form-control form_input"
                  :class="{'is-invalid': errors.has('register.密碼')}"
                  data-vv-scope="register"
                  placeholder="密碼"
                >
              </div>
            </div>
            <div class="member_basic_item">
              <div class="form-group d-flex align-items-center">
                <label for="register_email">信箱</label>
                <input
                  id="register_email"
                  v-model="register.email"
                  type="email"
                  name="信箱"
                  class="form-control form_input"
                  placeholder="信箱"
                >
              </div>
            </div>
            <div class="member_basic_item">
              <div class="form-group d-flex align-items-baseline">
                <label>地址</label>
                <div class="d-flex flex-column w-100">
                  <div class="d-flex">
                    <select
                      id="register_addrCountyName"
                      v-model="register.addrCountyName"
                      v-validate="'required'"
                      name="縣市"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('register.縣市')}"
                      data-vv-scope="register"
                      @change="filter_district(false)"
                    >
                      <option
                        value
                        disabled
                        selected
                        hidden
                      >
                        縣市
                      </option>
                      <option
                        v-for="item in option.address"
                        :key="item.addrCountyCode"
                        :value="item.addrCountyName"
                      >
                        {{ item.addrCountyName }}
                      </option>
                    </select>
                    <select
                      id="register_addrCityName"
                      v-model="register.addrCityName"
                      v-validate="'required'"
                      name="行政區"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('edit.行政區')}"
                      data-vv-scope="register"
                      @change="get_city_code(false)"
                    >
                      <option
                        value
                        disabled
                        selected
                        hidden
                      >
                        行政區
                      </option>
                      <option
                        v-for="item in district_data"
                        :key="item.addrCityCode"
                        :value="item.addrCityName"
                      >
                        {{ item.addrCityName }}
                      </option>
                    </select>
                  </div>
                  <input
                    id="register_addrDetail"
                    v-model="register.addrDetail"
                    v-validate="'required'"
                    type="text"
                    name="詳細地址"
                    class="form-control form_input mt-2"
                    :class="{'is-invalid': errors.has('register.詳細地址')}"
                    data-vv-scope="register"
                    placeholder="詳細地址"
                  >
                </div>
              </div>
            </div>
            <div class="member_basic_item">
              <div class="form-group d-flex align-items-center mb-3">
                <label for="register_birthDay">生日</label>
                <input
                  id="register_birthDay"
                  v-model="register.birthDay"
                  v-validate="'required'"
                  type="date"
                  name="生日"
                  class="form-control form_input"
                  :class="{'is-invalid': errors.has('register.生日')}"
                  data-vv-scope="register"
                >
              </div>
            </div>
            <div class="member_basic_item">
              <div class="form-group d-flex align-items-center">
                <label for="register_vehicleMobile">手機載具</label>
                <input
                  id="register_vehicleMobile"
                  v-model="register.vehicleMobile"
                  type="text"
                  name="手機載具"
                  class="form-control form_input"
                  placeholder="手機載具"
                >
              </div>
            </div>
            <div class="member_basic_item">
              <div class="form-group d-flex align-items-center mb-3">
                <label>性別</label>
                <div class="form-check form-check-inline">
                  <input
                    id="register_gender_female"
                    v-model.number="register.gender"
                    class="form-check-input"
                    type="radio"
                    :value="0"
                  >
                  <label
                    class="form-check-label"
                    for="register_gender_female"
                  >女</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    id="register_gender_male"
                    v-model.number="register.gender"
                    class="form-check-input"
                    type="radio"
                    :value="1"
                  >
                  <label
                    class="form-check-label"
                    for="register_gender_male"
                  >男</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    id="register_gender_undefined"
                    v-model.number="register.gender"
                    class="form-check-input"
                    type="radio"
                    checked
                    :value="2"
                  >
                  <label
                    class="form-check-label"
                    for="register_gender_undefined"
                  >未指定</label>
                </div>
              </div>
            </div>
            <div class="member_basic_item">
              <div class="form-group d-flex align-items-center">
                <label for="register_memo">備註</label>
                <input
                  id="register_memo"
                  v-model="register.memo"
                  type="text"
                  name="備註"
                  class="form-control form_input"
                  placeholder="備註"
                >
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-ce">
            <a
              href="#"
              class="btn btn_primary px-2"
              @click.prevent="member_register()"
            >
              <span>註冊</span>
            </a>
          </div>
        </div>
        <div
          v-if="member_content === 'detail' && nav_tabs === 'member_info'"
          class="content_body"
        >
          <div class="member_section">
            <div class="d-flex mb-3">
              <span class="section_title">基本資料</span>
              <a
                href="#"
                class="btn btn_primary ml-2 px-2 rounded-sm"
                @click.prevent="member_mode = 'edit', filter_district(true)"
              >修改會員資料</a>
              <a
                v-if="member_mode === 'edit'"
                href="#"
                class="btn btn_primary px-2 ml-2 rounded-sm"
                @click.prevent="update_member()"
              >更新會員資料</a>
              <a
                href="#"
                class="btn btn_primary ml-2 px-2 rounded-sm"
                @click.prevent="reset_password()"
              >重設密碼</a>
            </div>
            <div class="row">
              <div class="col-12 col-xl-6">
                <div class="member_basic_item">
                  <div
                    v-if="member_mode === 'edit'"
                    class="form-group d-flex align-items-center"
                  >
                    <label for="fullname">會員姓名</label>
                    <input
                      id="fullname"
                      v-model="member.fullname"
                      v-validate="'required'"
                      type="text"
                      name="會員姓名"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('edit.會員姓名')}"
                      data-vv-scope="edit"
                      placeholder="會員姓名"
                    >
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <span>會員姓名</span>
                    <span>{{ member.fullname }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>手機</span>
                    <span>{{ member.celnum }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div
                    v-if="member_mode === 'edit'"
                    class="form-group d-flex align-items-baseline"
                  >
                    <label>地址</label>
                    <div class="d-flex flex-column">
                      <div class="d-flex">
                        <select
                          id="city"
                          v-model="member.addrCountyName"
                          v-validate="'required'"
                          name="縣市"
                          class="form-control form_input"
                          :class="{'is-invalid': errors.has('edit.縣市')}"
                          data-vv-scope="edit"
                          @change="filter_district(true)"
                        >
                          <option
                            value
                            disabled
                            selected
                            hidden
                          >
                            縣市
                          </option>
                          <option
                            v-for="item in option.address"
                            :key="item.addrCountyCode"
                            :value="item.addrCountyName"
                          >
                            {{ item.addrCountyName }}
                          </option>
                        </select>
                        <select
                          id="district"
                          v-model="member.addrCityName"
                          v-validate="'required'"
                          name="行政區"
                          class="form-control form_input"
                          :class="{'is-invalid': errors.has('edit.行政區')}"
                          data-vv-scope="edit"
                          @change="get_city_code(true)"
                        >
                          <option
                            value
                            disabled
                            selected
                            hidden
                          >
                            行政區
                          </option>
                          <option
                            v-for="item in district_data"
                            :key="item.addrCityCode"
                            :value="item.addrCityName"
                          >
                            {{ item.addrCityName }}
                          </option>
                        </select>
                      </div>
                      <input
                        id="addrDetail"
                        v-model="member.addrDetail"
                        v-validate="'required'"
                        type="text"
                        name="詳細地址"
                        class="form-control form_input mt-2"
                        :class="{'is-invalid': errors.has('edit.詳細地址')}"
                        data-vv-scope="edit"
                        placeholder="詳細地址"
                      >
                    </div>
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <span>地址</span>
                    <span>{{ member.addrCountyName + member.addrCityName + member.addrDetail }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div
                    v-if="member_mode === 'edit'"
                    class="form-group d-flex align-items-center"
                  >
                    <label for="email">信箱</label>
                    <input
                      id="email"
                      v-model="member.email"
                      type="email"
                      name="信箱"
                      class="form-control form_input"
                      placeholder="信箱"
                    >
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <span>信箱</span>
                    <span>{{ member.email }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div
                    v-if="member_mode === 'edit'"
                    class="form-group d-flex align-items-center mb-3"
                  >
                    <label>性別</label>
                    <div class="form-check form-check-inline">
                      <input
                        id="gender_female"
                        v-model="member.gender"
                        class="form-check-input"
                        type="radio"
                        :value="0"
                      >
                      <label
                        class="form-check-label"
                        for="gender_female"
                      >女</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        id="gender_male"
                        v-model="member.gender"
                        class="form-check-input"
                        type="radio"
                        :value="1"
                      >
                      <label
                        class="form-check-label"
                        for="gender_male"
                      >男</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        id="gender_undefined"
                        v-model.number="member.gender"
                        class="form-check-input"
                        type="radio"
                        checked
                        :value="2"
                      >
                      <label
                        class="form-check-label"
                        for="gender_undefined"
                      >未指定</label>
                    </div>
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <span>性別</span>
                    <span v-if="member.gender === 0">女</span>
                    <span v-if="member.gender === 1">男</span>
                    <span v-if="member.gender === 2">未指定</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div
                    v-if="member_mode === 'edit'"
                    class="form-group d-flex align-items-center mb-3"
                  >
                    <label for="birthday">生日</label>
                    <input
                      id="birthday"
                      v-model="member.birthday"
                      v-validate="'required'"
                      type="date"
                      name="生日"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('edit.生日')}"
                      data-vv-scope="edit"
                    >
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <span>生日</span>
                    <span>{{ member.birthday }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div
                    v-if="member_mode === 'edit'"
                    class="form-group d-flex align-items-center"
                  >
                    <label for="vehicleMobile">手機載具</label>
                    <input
                      id="vehicleMobile"
                      v-model="member.vehicleMobile"
                      type="text"
                      name="手機載具"
                      class="form-control form_input"
                    >
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <span>手機載具</span>
                    <span>{{ member.vehicleMobile }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>VIP日期</span>
                    <span>{{ member.dtVip === '2000-01-01' ? '' : member.dtVip }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <label for="gpoint_select">G幣選擇</label>
                    <select
                      id="gpoint_select"
                      v-model="gpoint.code"
                      name="G幣選擇"
                      class="form-control form_input"
                    >
                      <option
                        key="point_account_gping_ex"
                        value="point_account_gping_ex"
                      >
                        吉品G幣
                      </option>
                      <option
                        key="point_account_wuer"
                        value="point_account_wuer"
                      >
                        無二G幣
                      </option>
                      <option
                        key="point_account_health"
                        value="point_account_health"
                      >
                        保健G幣
                      </option>
                      <option
                        key="point_account_fugui"
                        value="point_account_fugui"
                      >
                        福貴糕G幣
                      </option>
                    </select>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <label for="gpoint_select_value">金額</label>
                    <input
                      id="gpoint_select_value"
                      v-model.number="gpoint.value"
                    >
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <label for="gpoint_select_order_serial">訂單編號(若有)</label>
                    <input
                      id="gpoint_select_order_serial"
                      v-model.number="gpoint.orderSerial"
                    >
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <label for="gpoint_select_event">事由</label>
                    <input
                      id="gpoint_select_event"
                      v-model.number="gpoint.event"
                    >
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <label for="gpoint_select_desc">描述100字</label>
                    <input
                      id="gpoint_select_desc"
                      v-model.number="gpoint.desc"
                      maxlength="100"
                    >
                    <a
                      href="#"
                      class="btn btn_primary px-2 ml-2 rounded-sm"
                      @click.prevent="update_gpoint(member.userId)"
                    >追加</a>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-6">
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>客戶代碼</span>
                    <span>{{ member.userId }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>ERP客戶代碼</span>
                    <span>{{ member.erpId }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>客戶類型</span>
                    <span>{{ member.userType }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>客戶分類代碼</span>
                    <span>{{ member.categoryCode }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>加入日期</span>
                    <span>{{ member.dtJoin === '2000-01-01' ? '' : member.dtJoin }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>是否已轉ERP</span>
                    <span>{{ member.optionErp ? '是' : '否' }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div
                    v-if="member_mode === 'edit'"
                    class="form-group d-flex align-items-center mb-3"
                  >
                    <label>允許發送簡訊</label>
                    <div class="form-check form-check-inline">
                      <input
                        id="optionSendSms_true"
                        v-model="member.optionSendSms"
                        class="form-check-input"
                        type="radio"
                        :value="true"
                      >
                      <label
                        class="form-check-label"
                        for="optionSendSms_true"
                      >是</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        id="optionSendSms_false"
                        v-model="member.optionSendSms"
                        class="form-check-input"
                        type="radio"
                        :value="false"
                      >
                      <label
                        class="form-check-label"
                        for="optionSendSms_false"
                      >否</label>
                    </div>
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <span>允許發送簡訊</span>
                    <span>{{ member.optionSendSms ? '是' : '否' }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div
                    v-if="member_mode === 'edit'"
                    class="form-group d-flex align-items-center mb-3"
                  >
                    <label>允許發送EDM</label>
                    <div class="form-check form-check-inline">
                      <input
                        id="optionSendEdm_true"
                        v-model="member.optionSendEdm"
                        class="form-check-input"
                        type="radio"
                        :value="true"
                      >
                      <label
                        class="form-check-label"
                        for="optionSendEdm_true"
                      >是</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        id="optionSendEdm_false"
                        v-model="member.optionSendEdm"
                        class="form-check-input"
                        type="radio"
                        :value="false"
                      >
                      <label
                        class="form-check-label"
                        for="optionSendEdm_false"
                      >否</label>
                    </div>
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <span>允許發送EDM</span>
                    <span>{{ member.optionSendEdm ? '是' : '否' }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="member_content === 'detail' && nav_tabs === 'order_list'"
          class="content_body"
        >
          <div class="table-responsive">
            <table class="table table-borderless">
              <thead class="">
                <tr>
                  <th
                    scope="col"
                    width="100px"
                    class="text-color_primary"
                  >
                    訂購日期
                  </th>
                  <th
                    scope="col"
                    width="150px"
                    class="text-color_primary"
                  >
                    訂單編號/ERP/狀態
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    訂購人
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    收件人
                  </th>
                  <th
                    scope="col"
                    width="150px"
                    class="text-color_primary"
                  >
                    帳款
                  </th>
                  <th
                    scope="col"
                    width="100px"
                    class="text-color_primary"
                  >
                    到貨日
                  </th>
                  <th
                    scope="col"
                    width="100px"
                    class="text-right text-color_primary"
                  >
                    總價
                  </th>
                  <th scope="col">
                    備註
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="order_detail in member.order"
                  :key="order_detail.orderSerial"
                  :class="{ 'table_disabled': !order_detail.status }"
                >
                  <td>{{ order_detail.orderDate }}</td>
                  <td>
                    <a
                      href="#"
                      class="text-color_primary"
                      @click.prevent="get_order_detail(order_detail.orderSerial)"
                    >
                      {{ order_detail.orderSerial }}
                    </a>
                    <div>{{ order_detail.erpSerial }}</div>
                    <a
                      v-if="order_detail.status"
                      href="#"
                      class="d-inline-block btn btn_outline_danger rounded-sm px-2"
                      @click.prevent="cancel_order(order_detail.orderSerial)"
                    >取消訂單</a>
                    <div>{{ order_detail.erpSerial }}</div>
                    <div>{{ order_detail.receiptType }} {{ order_detail.receiptNumber }}</div>
                  </td>
                  <td>
                    <div>{{ order_detail.purFullname }}</div>
                    <div>{{ order_detail.purCelnum }}</div>
                    <div>{{ order_detail.purAddress }}</div>
                  </td>
                  <td>
                    <div>{{ order_detail.recFullname }}</div>
                    <div>{{ order_detail.recCelnum }}</div>
                    <div>{{ order_detail.recAddress }}</div>
                  </td>
                  <td>
                    <div
                      v-for="(bill, index) in order_detail.bill"
                      :key="index"
                      class="d-flex"
                    >
                      <div class="d-flex flex-column">
                        <span>{{ bill.paymentName }}</span>
                        <span
                          v-if="bill.paymentMode === '付款'"
                        >
                          <span
                            v-if="bill.payStatus || bill.paymentCode === 'cash_on_delivery_for_blackcat' || bill.paymentCode === 'pay_by_account'"
                            :class="{ 'text-success': bill.payStatus || bill.paymentCode === 'cash_on_delivery_for_blackcat' || bill.paymentCode === 'pay_by_account' }"
                          >{{ bill.payStatus ? '已付款' : bill.paymentCode === 'cash_on_delivery_for_blackcat' || bill.paymentCode === 'pay_by_account' ? '程序付款' : '未付款' }} {{ bill.amount | currency }}</span>
                          <span
                            v-else
                          >
                            <a
                              v-if="order_detail.status"
                              class="d-inline-block btn btn_outline_danger rounded-sm px-2"
                              href="#"
                              @click.prevent="confirm_order_item(order_detail, bill)"
                            >未付款 {{ bill.amount | currency }}</a>
                            <span
                              v-else
                              class="d-inline-block rounded-sm px-2"
                            >已取消 {{ bill.amount | currency }}</span>
                          </span>
                        </span>
                        <span
                          v-else
                        >
                          <span class="d-inline-block rounded-sm px-2">退款 {{ bill.amount | currency }}</span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>{{ order_detail.arriveDate }}</div>
                    <div class="text-danger">
                      {{ order_detail.arriveTime }}
                    </div>
                    <div class="d-flex">
                      <img
                        :class="order_detail.outsideDepotCode.includes('903') ? 'order_icon' : 'order_icon_none'"
                        src="@/assets/images/harvest.png"
                      >
                      <img
                        :class="order_detail.outsideDepotCode.includes('904') ? 'order_icon' : 'order_icon_none'"
                        src="@/assets/images/fruits.png"
                      >
                      <img
                        :class="order_detail.outsideDepotCode.includes('905') ? 'order_icon' : 'order_icon_none'"
                        src="@/assets/images/hazmat.png"
                      >
                    </div>
                  </td>
                  <td class="text-right">
                    <div>{{ order_detail.amountTotal | currency }}</div>
                    <div v-if="order_detail.amountReturn === 0" />
                    <div v-else>
                      {{ order_detail.amountReturn | currency }}
                    </div>
                    <div v-if="order_detail.amountReturn === 0 || order_detail.amountTotal === order_detail.amountValue" />
                    <div v-else>
                      {{ order_detail.amountValue | currency }}
                    </div>
                  </td>
                  <td>
                    <div v-if="order_detail.memoCustomer === ''">
                      x
                    </div>
                    <div v-else>
                      {{ order_detail.memoCustomer }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          v-if="member_content === 'detail' && nav_tab_detail === true"
          class="content_body"
        >
          <ul class="nav nav-tabs mb-3">
            <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                :class="{ 'active': nav_tabs === 'order_info' }"
                @click.prevent="switch_nav('order_info', true)"
              >訂單資料</a>
            </li>
            <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                :class="{ 'active': nav_tabs === 'order_content' }"
                @click.prevent="switch_nav('order_content', true)"
              >訂購內容</a>
            </li>
            <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                :class="{ 'active': nav_tabs === 'order_contact' }"
                @click.prevent="switch_nav('order_contact', true)"
              >聯絡資訊</a>
            </li>
            <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                :class="{ 'active': nav_tabs === 'order_price' }"
                @click.prevent="switch_nav('order_price', true)"
              >價格資訊</a>
            </li>
            <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                :class="{ 'active': nav_tabs === 'order_bill' }"
                @click.prevent="switch_nav('order_bill', true)"
              >帳款</a>
            </li>
            <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                :class="{ 'active': nav_tabs === 'order_receipt' }"
                @click.prevent="switch_nav('order_receipt', true)"
              >發票</a>
            </li>
          </ul>
          <div class="d-flex mb-2">
            <a
              v-if="order.status && (nav_tabs === 'order_info' || nav_tabs === 'order_contact')"
              href="#"
              class="btn btn_primary px-2 rounded-sm"
              @click.prevent="order_mode = 'edit_rec', filter_order_district()"
            >修改收件資料</a>
            <a
              v-if="order.status && order_mode === 'edit_rec'"
              href="#"
              class="btn btn_primary px-2 ml-2 rounded-sm"
              @click.prevent="update_rec()"
            >更新收件資料</a>
            <a
              v-if="order.status && nav_tabs === 'order_receipt'"
              href="#"
              class="btn btn_primary px-2 ml-2 rounded-sm"
              @click.prevent="order_mode = 'edit_receipt'"
            >修改發票資料</a>
            <a
              v-if="order.status && order_mode === 'edit_receipt'"
              href="#"
              class="btn btn_primary px-2 ml-2 rounded-sm"
              @click.prevent="update_receipt()"
            >更新發票資料</a>
          </div>
          <div class="container-fluid">
            <div
              v-if="nav_tabs === 'order_info'"
              class="row"
            >
              <div class="col-12 col-xl-4">
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>訂單狀態</span>
                    <span :class="{ 'text-success': order.status, 'text-danger': !order.status }">{{ order.status ? '建立成功' : '已取消' }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>訂單編號</span>
                    <span>{{ order.orderSerial }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>系統/來源</span>
                    <span>{{ order.systemCode }}/{{ order.platformCode }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>訂購日期</span>
                    <span>{{ order.orderDate }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>下單人</span>
                    <span>{{ order.adminId }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div
                    v-if="order_mode === 'edit_rec'"
                    class="form-group d-flex align-items-center"
                  >
                    <label for="delivery">運送方式</label>
                    <select
                      id="delivery"
                      v-model="order.delivery"
                      v-validate="'required'"
                      name="運送方式"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('edit.運送方式')}"
                      data-vv-scope="edit"
                    >
                      <option
                        value
                        disabled
                        selected
                        hidden
                      >
                        運送方式
                      </option>
                      <option
                        v-for="item in option.delivery"
                        :key="item"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <span>運送方式</span>
                    <span>{{ order.delivery }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>回饋碼</span>
                    <span>{{ order.deliverySerial }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <div
                      v-if="order_mode === 'edit_rec'"
                      class="form-group d-flex align-items-center"
                    >
                      <label for="memoCustomer">客戶備註</label>
                      <input
                        id="memoCustomer"
                        v-model="order.memoCustomer"
                        type="text"
                        name="客戶備註"
                        class="form-control form_input"
                        placeholder="客戶備註"
                      >
                    </div>
                    <div
                      v-else
                      class="d-flex align-items-baseline"
                    >
                      <span>客戶備註</span>
                      <span>{{ order.memoCustomer }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="member_basic_item">
                  <div
                    v-if="order_mode === 'edit_rec'"
                    class="form-group d-flex align-items-baseline"
                  >
                    <label for="recAddrDetail">到貨地址</label>
                    <div class="d-flex flex-column">
                      <div class="d-flex">
                        <select
                          id="city"
                          v-model="order.rec.recAddrCountyName"
                          v-validate="'required'"
                          name="到貨縣市"
                          class="form-control form_input"
                          :class="{'is-invalid': errors.has('edit.到貨縣市')}"
                          data-vv-scope="edit"
                          @change="filter_order_district()"
                        >
                          <option
                            value
                            disabled
                            selected
                            hidden
                          >
                            縣市
                          </option>
                          <option
                            v-for="item in option.address"
                            :key="item.addrCountyCode"
                            :value="item.addrCountyName"
                          >
                            {{ item.addrCountyName }}
                          </option>
                        </select>
                        <select
                          id="district"
                          v-model="order.rec.recAddrCityName"
                          v-validate="'required'"
                          name="到貨行政區"
                          class="form-control form_input"
                          :class="{'is-invalid': errors.has('edit.到貨行政區')}"
                          data-vv-scope="edit"
                          @change="get_order_city_code()"
                        >
                          <option
                            value
                            disabled
                            selected
                            hidden
                          >
                            行政區
                          </option>
                          <option
                            v-for="item in order_district_data"
                            :key="item.addrCityCode"
                            :value="item.addrCityName"
                          >
                            {{ item.addrCityName }}
                          </option>
                        </select>
                      </div>
                      <input
                        id="recAddrDetail"
                        v-model="order.rec.recAddrDetail"
                        v-validate="'required'"
                        type="text"
                        name="到貨地址"
                        class="form-control form_input mt-2"
                        :class="{'is-invalid': errors.has('edit.到貨地址')}"
                        data-vv-scope="edit"
                        placeholder="到貨地址"
                      >
                    </div>
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <span>到貨地址</span>
                    <span>{{ order.rec.recAddrCountyName + order.rec.recAddrCityName + order.rec.recAddrDetail }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div
                    v-if="order_mode === 'edit_rec'"
                    class="form-group d-flex align-items-center"
                  >
                    <label for="arriveDate">到貨日期</label>
                    <input
                      id="arriveDate"
                      v-model="order.arriveDate"
                      v-validate="'required'"
                      type="date"
                      name="到貨日期"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('edit.到貨日期')}"
                      data-vv-scope="edit"
                      placeholder="到貨日期"
                    >
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <span>到貨日期</span>
                    <span>{{ order.arriveDate }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div
                    v-if="order_mode === 'edit_rec'"
                    class="form-group d-flex align-items-center"
                  >
                    <label for="arriveTime">到貨時段</label>
                    <select
                      id="arriveTime"
                      v-model="order.arriveTime"
                      v-validate="'required'"
                      name="到貨時段"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('edit.到貨時段')}"
                      data-vv-scope="edit"
                    >
                      <option
                        value
                        disabled
                        selected
                        hidden
                      >
                        到貨時段
                      </option>
                      <option
                        v-for="item in option.arriveTime"
                        :key="item"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <span>到貨時段</span>
                    <span>{{ order.arriveTime }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>設定轉ERP</span>
                    <span>{{ order.erpStatus ? '是' : '否' }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>已轉ERP</span>
                    <span>{{ order.erpTransStatus ? '是' : '否' }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>ERP單號</span>
                    <span>{{ order.erpSerial }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>IP</span>
                    <span>{{ order.ip }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>出貨狀態</span>
                    <span>{{ order.storageProcessedStatus ? '已出貨' : '未出貨' }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>出貨日期</span>
                    <span>{{ order.storageProcessedDate }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>強制免運</span>
                    <span>{{ order.option.optionNoTransFee ? '是' : '否' }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>急件</span>
                    <span>{{ order.option.optionUrgent ? '是' : '否' }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>分溫層寄送</span>
                    <span>{{ order.option.optionSplitup ? '是' : '否' }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>是否附DM</span>
                    <span>{{ order.option.optionDm ? '是' : '否' }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="nav_tabs === 'order_content'"
              class="table-responsive"
            >
              <table class="table table-borderless">
                <thead class="">
                  <tr>
                    <th
                      scope="col"
                      width="30px"
                      class="text-color_primary"
                    >
                      項目
                    </th>
                    <th
                      scope="col"
                      width="150px"
                      class="text-color_primary"
                    >
                      編號
                    </th>
                    <th
                      scope="col"
                      class="text-color_primary"
                    >
                      名稱
                    </th>
                    <th
                      scope="col"
                      class="text-color_primary"
                    >
                      規格
                    </th>
                    <th
                      scope="col"
                      width="100px"
                      class="text-center text-color_primary"
                    >
                      運送
                    </th>
                    <th
                      scope="col"
                      width="130px"
                      class="text-center text-color_primary"
                    >
                      價格/商品類型
                    </th>
                    <th
                      scope="col"
                      width="100px"
                      class="text-center text-color_primary"
                    >
                      原價/單價
                    </th>
                    <th
                      scope="col"
                      width="100px"
                      class="text-center text-color_primary"
                    >
                      數量/退貨
                    </th>
                    <th
                      scope="col"
                      width="80px"
                      class="text-right text-color_primary"
                    >
                      小計
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(order_item, index) in order.items"
                    :key="index"
                  >
                    <td>
                      <div class="form-check form-check-inline">
                        <input
                          :id="'check' + order_item.serialNumber"
                          class="form-check-input"
                          type="checkbox"
                          :checked="return_items.find((itm) => itm.serialNumber === order_item.serialNumber)"
                          :disabled="!order_item.optionReturn"
                          @click="item_check(order_item, $event)"
                        >
                      </div>
                    </td>
                    <td>{{ order_item.prodSerial }}</td>
                    <td>{{ order_item.prodName }}</td>
                    <td>{{ order_item.standard }}</td>
                    <td class="text-center">
                      {{ order_item.transportType }}
                    </td>
                    <td class="text-center">
                      <div>{{ order_item.priceType }}</div>
                      <div :class="order_item.prodMode == '退貨品' ? 'text-danger' : ''">
                        {{ order_item.prodMode }}
                      </div>
                    </td>
                    <td class="text-center">
                      <div>{{ order_item.priceOriginal | currency }}</div>
                      <div class="text-danger">
                        {{ order_item.price | currency }}
                      </div>
                    </td>
                    <td class="text-center">
                      <div>{{ order_item.quantity | currency }}</div>
                      <div class="text-danger">
                        {{ order_item.returnQuantity | currency }}
                      </div>
                    </td>
                    <td class="text-right">
                      <div :class="order_item.prodMode == '退貨品' ? 'text-danger' : ''">
                        {{ order_item.amount | currency }}
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="9">
                      <div
                        v-if="order.status"
                        class="d-flex mb-2"
                      >
                        <a
                          v-if="order.storageProcessedStatus"
                          href="#"
                          class="btn btn_primary px-2 ml-2 rounded-sm"
                          @click="show_modal"
                        >部分退貨</a>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <!-- Modal -->
              <div
                id="modalReturnItems"
                class="modal fade"
                tabindex="-1"
                aria-labelledby="returnModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5
                        id="returnModalLabel"
                        class="modal-title"
                      >
                        確認退貨內容
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="text-center">
                        訂單編號: <span>{{ order.orderSerial }}</span>
                      </div>
                      <div
                        v-for="return_item in return_items"
                        :key="return_item.serialNumber"
                        class="d-flex"
                      >
                        <div class="p-2 flex-fill text-danger">
                          {{ return_item.prodName }}:
                        </div>
                        <div class="p-2 flex-fill">
                          <select
                            v-model="return_item.inputQuantity"
                            class="form-control form_input"
                            :value="return_item.inputQuantity"
                          >
                            <option
                              v-for="(item, index) in return_item.quantity"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="d-flex">
                        <div class="p-2 flex-fill text-left">
                          退貨選擇:
                        </div>
                        <div class="p-2 flex-fill">
                          <select
                            id="return_payment_code"
                            v-model="return_paymentCode"
                            name="退款類型"
                            class="form-control form_input"
                          >
                            <option
                              v-for="(item, index) in option.returnItemType"
                              :key="index"
                              :value="item.code"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div>
                        <label for="bill_requestNumber">回饋碼:</label>
                        <input
                          id="bill_requestNumber"
                          v-model="return_requestNumber"
                          type="text"
                          name="回饋碼"
                          class="form-control"
                          :class="{'is-invalid': errors.has('回饋碼')}"
                          placeholder="輸入回饋碼"
                        >
                      </div>
                      <div>
                        <label for="bill_memo">備註:</label>
                        <input
                          id="bill_memo"
                          v-model="return_memo"
                          type="text"
                          name="備註"
                          class="form-control"
                          :class="{'is-invalid': errors.has('備註')}"
                          placeholder="備註"
                        >
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="return_order_item(order)"
                      >
                        確認退貨
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        關閉
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="nav_tabs === 'order_contact'"
              class="row"
            >
              <div class="col-12 col-xl-6">
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>訂購人姓名</span>
                    <span>{{ order.pur.purFullname }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>訂購人手機</span>
                    <span>{{ order.pur.purCelnum }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>訂購人信箱</span>
                    <span>{{ order.pur.purEmail }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>訂購人地址</span>
                    <span>{{ order.pur.purAddrZip + order.pur.purAddrCountyName + order.pur.purAddrCityName + order.pur.purAddrDetail }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-6">
                <div class="member_basic_item">
                  <div
                    v-if="order_mode === 'edit_rec'"
                    class="form-group d-flex align-items-center"
                  >
                    <label for="recFullname">收件人姓名</label>
                    <input
                      id="recFullname"
                      v-model="order.rec.recFullname"
                      v-validate="'required'"
                      type="text"
                      name="收件人姓名"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('edit.收件人姓名')}"
                      data-vv-scope="edit"
                      placeholder="收件人姓名"
                    >
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <span>收件人姓名</span>
                    <span>{{ order.rec.recFullname }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div
                    v-if="order_mode === 'edit_rec'"
                    class="form-group d-flex align-items-center"
                  >
                    <label for="recCelnum">收件人手機</label>
                    <input
                      id="recCelnum"
                      v-model="order.rec.recCelnum"
                      v-validate="'required'"
                      type="text"
                      name="收件人手機"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('edit.收件人手機')}"
                      data-vv-scope="edit"
                      placeholder="收件人手機"
                    >
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <span>收件人手機</span>
                    <span>{{ order.rec.recCelnum }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>收件人地址</span>
                    <span>{{ order.rec.recAddrZip + order.rec.recAddrCountyName + order.rec.recAddrCityName + order.rec.recAddrDetail }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="nav_tabs === 'order_price'"
              class="row"
            >
              <div class="col-12">
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>總價</span>
                    <span>{{ order.amount.amountTotal | currency }}元</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>應收金額</span>
                    <span>{{ order.amount.amountReceivables | currency }}元</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="nav_tabs === 'order_bill'"
              class="table-responsive"
            >
              <table class="table table-borderless">
                <thead class="">
                  <tr>
                    <th
                      scope="col"
                      class="text-color_primary"
                    >
                      項目
                    </th>
                    <th
                      scope="col"
                      class="text-color_primary"
                    >
                      類型
                    </th>
                    <th
                      scope="col"
                      class="text-color_primary"
                    >
                      金額
                    </th>
                    <th
                      scope="col"
                      class="text-color_primary"
                    >
                      狀態
                    </th>
                    <th
                      scope="col"
                      class="text-color_primary"
                    >
                      付款日期
                    </th>
                    <th
                      scope="col"
                      class="text-color_primary"
                    >
                      管理者
                    </th>
                    <th
                      scope="col"
                      class="text-color_primary"
                    >
                      回饋碼
                    </th>
                    <th
                      scope="col"
                      class="text-color_primary"
                    >
                      點數變更
                    </th>
                    <th
                      scope="col"
                      class="text-color_primary"
                    >
                      備註
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="bill in order.bill"
                    :key="bill.serialNumber"
                  >
                    <td>{{ bill.paymentName }}</td>
                    <td
                      :class="{ 'text-success': bill.paymentMode === '付款', 'text-danger': bill.paymentMode === '退款' }"
                    >
                      {{ bill.paymentMode }}
                    </td>
                    <td>{{ bill.amount | currency }}</td>
                    <td
                      v-if="bill.paymentMode === '付款' || bill.paymentCode === 'cash_on_delivery_for_blackcat' || bill.paymentCode === 'pay_by_account'"
                      :class="{ 'text-success': bill.payStatus || bill.paymentCode === 'cash_on_delivery_for_blackcat' || bill.paymentCode === 'pay_by_account', 'text-danger': !bill.payStatus }"
                    >
                      {{ bill.payStatus ? '已付款' : bill.paymentCode === 'cash_on_delivery_for_blackcat' || bill.paymentCode === 'pay_by_account' ? '程序付款' : '未付款' }}
                    </td>
                    <td
                      v-else
                      class="text-danger"
                    >
                      已退款
                    </td>
                    <td
                      v-if="bill.paymentMode === '付款'"
                    >
                      {{ bill.payDate === '2000-01-01 00:00:00' ? '' : bill.payDate }}
                    </td>
                    <td
                      v-else
                    >
                      {{ bill.returnDate }}
                    </td>
                    <td>{{ bill.paymentMode === '付款' ? bill.payAdminId : bill.returnAdminId }}</td>
                    <td>{{ bill.requestNumber }} {{ bill.requestBank }} <span v-if="bill.requestDate !== '2000-01-01'">{{ bill.requestDate }}</span></td>
                    <td>{{ bill.pointValue | currency }}</td>
                    <td>{{ bill.memo }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-if="nav_tabs === 'order_receipt'"
              class="row"
            >
              <div class="col-12">
                <div class="member_basic_item">
                  <div class="d-flex align-items-baseline">
                    <span>開立狀態</span>
                    <span :class="{ 'text-success': order.receipt.receiptIssueStatus, 'text-danger': !order.receipt.receiptIssueStatus }">{{ order.receipt.receiptIssueStatus ? '已開立' : '尚未開立' }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div
                    v-if="order_mode === 'edit_receipt'"
                    class="form-group d-flex align-items-center"
                  >
                    <label for="receiptType">發票類型</label>
                    <select
                      id="receiptType"
                      v-model="order.receipt.receiptType"
                      v-validate="'required'"
                      name="發票類型"
                      class="form-control form_input"
                      :class="{'is-invalid': errors.has('edit.發票類型')}"
                      data-vv-scope="edit"
                    >
                      <option
                        value
                        disabled
                        selected
                        hidden
                      >
                        發票類型
                      </option>
                      <option
                        v-for="item in option.receiptType"
                        :key="item"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <span>發票類型</span>
                    <span>{{ order.receipt.receiptType }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div
                    v-if="order_mode === 'edit_receipt'"
                    class="form-group d-flex align-items-center"
                  >
                    <label for="receiptUbn">統一編號</label>
                    <input
                      id="receiptUbn"
                      v-model="order.receipt.receiptUbn"
                      type="text"
                      name="統一編號"
                      class="form-control form_input"
                      placeholder="統一編號"
                    >
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <span>統一編號</span>
                    <span>{{ order.receipt.receiptUbn }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div
                    v-if="order_mode === 'edit_receipt'"
                    class="form-group d-flex align-items-center"
                  >
                    <label for="receiptTitle">發票抬頭</label>
                    <input
                      id="receiptTitle"
                      v-model="order.receipt.receiptTitle"
                      type="text"
                      name="發票抬頭"
                      class="form-control form_input"
                      placeholder="發票抬頭"
                    >
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <span>發票抬頭</span>
                    <span>{{ order.receipt.receiptTitle }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div
                    v-if="order_mode === 'edit_receipt'"
                    class="form-group d-flex align-items-center"
                  >
                    <label for="receiptNumber">發票號碼</label>
                    <input
                      id="receiptNumber"
                      v-model="order.receipt.receiptNumber"
                      type="text"
                      name="發票號碼"
                      class="form-control form_input"
                      placeholder="發票號碼"
                    >
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <span>發票號碼</span>
                    <span>{{ order.receipt.receiptNumber }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div
                    v-if="order_mode === 'edit_receipt'"
                    class="form-group d-flex align-items-center"
                  >
                    <label for="receiptDate">開立日期</label>
                    <input
                      id="receiptDate"
                      v-model="order.receipt.receiptDate"
                      type="date"
                      name="開立日期"
                      class="form-control form_input"
                    >
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <span>開立日期</span>
                    <span>{{ order.receipt.receiptDate === '2000-01-01' ? '' : order.receipt.receiptDate }}</span>
                  </div>
                </div>
                <div class="member_basic_item">
                  <div
                    v-if="order_mode === 'edit_receipt'"
                    class="form-group d-flex align-items-center"
                  >
                    <label for="receiptVehicleMobile">手機載具</label>
                    <input
                      id="receiptVehicleMobile"
                      v-model="order.receipt.receiptVehicleMobile"
                      type="text"
                      name="手機載具"
                      class="form-control form_input"
                      placeholder="手機載具"
                    >
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <span>手機載具</span>
                    <span>{{ order.receipt.receiptVehicleMobile }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="member_content === 'detail' && nav_tabs === 'receipt'"
          class="content_body"
        >
          <div class="table-responsive">
            <table class="table table-borderless">
              <thead class="">
                <tr>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    序號
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    統編
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    抬頭
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    最後使用日期
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="receipt in member.receipt"
                  :key="receipt.serialNumber"
                >
                  <td>{{ receipt.serialNumber }}</td>
                  <td>{{ receipt.receiptUbn }}</td>
                  <td>{{ receipt.receiptTitle }}</td>
                  <td>{{ receipt.dtLastUse }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          v-if="member_content === 'detail' && nav_tabs === 'coupon'"
          class="content_body"
        >
          <div class="table-responsive">
            <table class="table table-borderless">
              <thead class="">
                <tr>
                  <th
                    scope="col"
                    width="150px"
                    class="text-color_primary"
                  >
                    序號/折扣碼
                  </th>
                  <th
                    scope="col"
                    class="text-center text-color_primary"
                  >
                    名稱
                  </th>
                  <th
                    scope="col"
                    width="150px"
                    class="text-center text-color_primary"
                  >
                    類型
                  </th>
                  <th
                    scope="col"
                    width="80px"
                    class="text-center text-color_primary"
                  >
                    限定次數
                  </th>
                  <th
                    scope="col"
                    width="120px"
                    class="text-center text-color_primary"
                  >
                    可使用/已使用
                  </th>
                  <th
                    scope="col"
                    width="100px"
                    class="text-center text-color_primary"
                  >
                    數量/金額
                  </th>
                  <th
                    scope="col"
                    width="100px"
                    class="text-center text-color_primary"
                  >
                    生效/失效
                  </th>
                  <th
                    scope="col"
                    width="100px"
                    class="text-center text-color_primary"
                  >
                    狀態/來源
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(coupon) in member.coupon"
                  :key="coupon.couponCode"
                >
                  <td>
                    <div>{{ coupon.serialNumber }}</div>
                    <div>{{ coupon.couponCode }}</div>
                  </td>
                  <td class="text-center">
                    {{ coupon.name }}
                  </td>
                  <td class="text-center">
                    {{ coupon.type }}
                  </td>
                  <td class="text-center">
                    {{ coupon.optionLimitPerMonth ? '是' : '否' }}
                  </td>
                  <td class="text-center">
                    <div>{{ coupon.optionAccountTimes }}</div>
                    <div>{{ coupon.optionAccountUsed }}</div>
                  </td>
                  <td class="text-center">
                    {{ coupon.prodValue }}
                  </td>
                  <td class="text-center">
                    <div>{{ coupon.dtEffective }}</div>
                    <div>{{ coupon.dtInvalid }}</div>
                  </td>
                  <td class="text-center">
                    <div>{{ coupon.status }}</div>
                    <div>{{ coupon.systemCode }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          v-if="member_content === 'detail' && nav_tabs === 'point_account'"
          class="content_body"
        >
          <div class="table-responsive">
            <table class="table table-borderless">
              <thead class="">
                <tr>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    付款方式
                  </th>
                  <th
                    scope="col"
                    width="100px"
                    class="text-center text-color_primary"
                  >
                    金額
                  </th>
                  <th
                    scope="col"
                    class="text-center text-color_primary"
                  >
                    狀態
                  </th>
                  <th
                    scope="col"
                    class="text-center text-color_primary"
                  >
                    上架日期
                  </th>
                  <th
                    scope="col"
                    class="text-center text-color_primary"
                  >
                    最後日期
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(point, index) in member.pointAccount"
                  :key="index"
                >
                  <td>{{ point.paymentName }}</td>
                  <td class="text-center">
                    {{ point.pointValue }}
                  </td>
                  <td class="text-center">
                    {{ point.status }}
                  </td>
                  <td class="text-center">
                    {{ point.dtPublic }}
                  </td>
                  <td class="text-center">
                    {{ point.dtUnPublic }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          v-if="member_content === 'detail' && nav_tabs === 'log_point'"
          class="content_body"
        >
          <div class="table-responsive">
            <table class="table table-borderless">
              <thead class="">
                <tr>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    訂單編號
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    名稱
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    理由
                  </th>
                  <th
                    scope="col"
                    class="text-center text-color_primary"
                  >
                    付款方式
                  </th>
                  <th
                    scope="col"
                    width="100px"
                    class="text-center text-color_primary"
                  >
                    變動金額
                  </th>
                  <th
                    scope="col"
                    width="120px"
                    class="text-center text-color_primary"
                  >
                    原金額/新金額
                  </th>
                  <th
                    scope="col"
                    width="100px"
                    class="text-center text-color_primary"
                  >
                    人員
                  </th>
                  <th
                    scope="col"
                    width="100px"
                    class="text-center text-color_primary"
                  >
                    來源
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(logPoint, index) in member.logPoint"
                  :key="index"
                >
                  <td>{{ logPoint.orderSerial }}</td>
                  <td>{{ logPoint.eventName }}</td>
                  <td>{{ logPoint.eventReason }}</td>
                  <td>{{ logPoint.paymentName }}</td>
                  <td class="text-center">
                    {{ logPoint.pointChange }}
                  </td>
                  <td class="text-center">
                    <div>{{ logPoint.pointInitial | currency }}</div>
                    <div>{{ logPoint.pointFinal | currency }}</div>
                  </td>
                  <td class="text-center">
                    {{ logPoint.adminId }}
                  </td>
                  <td class="text-center">
                    <div>{{ logPoint.systemCode }}</div>
                    <div>{{ logPoint.platformCode }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  API_GET_MEMBER,
  API_GET_MEMBER_DETAIL,
  API_UPDATE_MEMBER,
  API_GET_ORDER_DETIAL,
  API_CANCEL_ORDER,
  API_UPDATE_ORDER,
  API_UPDATE_ORDER_RECEIPT,
  API_UPDATE_ORDER_BILL,
  API_UPDATE_ORDER_RETURN_ITEM,
  API_REGISTER_MEMBER,
  API_RESET_PASSWORD,
  API_UPDATE_MEMBER_GPOINT,
} from '@/plugins/api';

export default {
  data() {
    return {
      now_page: 'member',
      search: {
        /* 搜尋資料 */
        mode: 1,
        value: '',
      },
      /* G幣增減 */
      gpoint: {
        code: '', /* 代碼 */
        value: 0, /* 金額 */
        orderSerial: '', /* 來源訂單編號 */
        event: '', /* 事由 */
        desc: '', /* 描述 */
      },
      member_content: '', /* 會員列表 、會員細節 */
      member_mode: '', /* 會員資料編輯模式 */
      order_mode: '',
      return_items: [], /* 確定要退的商品資料 */
      return_paymentCode: '',
      return_requestNumber: '',
      return_memo: '',
      nav_tabs: '', /* 訂單列表、訂單細節 */
      nav_tab_detail: false,
      members: [], /* 會員列表資料 */
      member: {}, /* 會員細節 */
      register: {},
      district_data: [], /* 行政區資料 */
      order_district_data: [],
      order: {}, /* 訂單細節 */
    };
  },
  computed: {
    ...mapGetters('system', ['option']),
  },
  async mounted() {
    const vm = this;
    await vm.$store.dispatch('page_load', true);
    await vm.$store.dispatch('now_page', vm.now_page);
    await vm.get_system_option();
    await vm.$store.dispatch('page_load', false);
  },
  methods: {
    ...mapActions('system', ['get_system_option']),
    /* 初始化驗證 */
    async init_validate() {
      const vm = this;
      vm.$validator.pause();
      vm.$nextTick(() => {
        vm.$validator.reset();
        vm.$validator.errors.clear('search');
        vm.$validator.errors.clear('register');
        vm.$validator.errors.clear('edit');
        vm.$validator.fields.items.forEach((field) => field.reset());
        vm.$validator.fields.items.forEach((field) => vm.errors.remove(field));
        vm.$validator.resume();
      });
    },
    /* 切換搜尋模式 */
    async switch_searchmode() {
      const vm = this;
      setTimeout(() => {
        vm.search.value = '';
        vm.errors.clear('search');
        vm.$validator.reset();
      }, 100);
    },
    /* switch_nav */
    async switch_nav(content, orderDetailStatus) {
      const vm = this;
      vm.nav_tabs = content;
      vm.nav_tab_detail = orderDetailStatus;
    },
    /* 取得會員列表 */
    async get_members() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('search');
      if (validateResult && vm.search.value !== '') {
        vm.$store.dispatch('page_load', true);
        const data = {
          mode: String(vm.search.mode),
          value: vm.search.value,
        };
        const response = await API_GET_MEMBER(data);
        const res = response.data;
        // console.log('取得會員列表 成功', response);
        if (res.code === '0000') {
          vm.members = res.data;
          vm.member_content = 'list';
          vm.switch_nav('', false);
        }
        vm.$store.dispatch('page_load', false);
      } else if (!validateResult && vm.search.value === '') {
        vm.$swal({
          icon: 'warning',
          title: '請輸入搜尋值!',
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 取得會員資料 */
    async get_member_detail(userId) {
      const vm = this;
      vm.$store.dispatch('page_load', true);
      const response = await API_GET_MEMBER_DETAIL(userId);
      const res = response.data;
      // console.log('取得會員資料 成功', response);
      if (res.code === '0000') {
        vm.member = res.data;
        vm.member_content = 'detail';
      }
      vm.$store.dispatch('page_load', false);
    },
    /* 取得行政區 */
    async filter_district(isMamber) {
      const vm = this;
      if (isMamber) {
        vm.option.address.forEach((item) => {
          if (item.addrCountyName === vm.member.addrCountyName) {
            vm.district_data = item.cities;
          }
        });
        await vm.get_city_code(isMamber);
      } else {
        vm.option.address.forEach((item) => {
          if (item.addrCountyName === vm.register.addrCountyName) {
            vm.district_data = item.cities;
          }
        });
        await vm.get_city_code(isMamber);
      }
    },
    async filter_order_district() {
      const vm = this;
      vm.option.address.forEach((item) => {
        if (item.addrCountyName === vm.order.rec.recAddrCountyName) {
          vm.order_district_data = item.cities;
        }
      });
      await vm.get_order_city_code();
    },
    /* 取得郵遞區號 */
    async get_city_code(isMamber) {
      const vm = this;
      if (vm.district_data.length !== 0) {
        if (isMamber) {
          vm.district_data.forEach((item) => {
            if (item.addrCityName === vm.member.addrCityName) {
              vm.member.addrCityName = item.addrCityName;
              vm.member.addrCityCode = item.addrCityCode;
            }
          });
        } else {
          vm.district_data.forEach((item) => {
            if (item.addrCityName === vm.register.addrCityName) {
              vm.register.addrCityName = item.addrCityName;
              vm.register.addrCityCode = item.addrCityCode;
            }
          });
        }
      }
    },
    /* 取得郵遞區號 */
    async get_order_city_code() {
      const vm = this;
      if (vm.order_district_data.length !== 0) {
        vm.order_district_data.forEach((item) => {
          if (item.addrCityName === vm.order.rec.recAddrCityName) {
            vm.order.rec.recAddrCityName = item.addrCityName;
            vm.order.rec.recAddrCityCode = item.addrCityCode;
          }
        });
      }
    },
    /* 更新會員資料 */
    async update_member() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('edit');
      if (validateResult) {
        const memberData = {
          status: vm.member.status,
          userId: vm.member.userId,
          fullname: vm.member.fullname,
          gender: vm.member.gender,
          birthDay: vm.member.birthday,
          email: vm.member.email || '',
          telnum: vm.member.celnum,
          telnumExt: vm.member.telnumExt,
          addrCityCode: vm.member.addrCityCode,
          addrDetail: vm.member.addrDetail,
          memo: vm.member.memo,
          vehicleMobile: vm.member.vehicleMobile,
          optionSendSms: vm.member.optionSendSms,
          optionSendEdm: vm.member.optionSendEdm,
        };
        const response = await API_UPDATE_MEMBER(memberData);
        const res = response.data;
        // console.log('更新會員資料 成功');
        if (res.code === '0000') {
          vm.$swal({
            icon: 'success',
            title: '成功',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
          await vm.get_member_detail(vm.member.userId);
          vm.member_mode = '';
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 導向訂單頁 */
    async to_order(userId) {
      const vm = this;
      try {
        await vm.$store.dispatch('cart/get_cart_serial', userId);
        vm.$router.push('/dashboard/toorder');
      } catch (error) {
        vm.$swal({
          icon: 'error',
          title: '取得資料失敗!',
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 取得訂單細節 */
    async get_order_detail(orderSerial) {
      const vm = this;
      vm.$store.dispatch('page_load', true);
      const response = await API_GET_ORDER_DETIAL(orderSerial);
      const res = response.data;
      // console.log('取得訂單細節 成功', response);
      if (res.code === '0000') {
        vm.order = res.data;
        vm.switch_nav('order_info', true);
        vm.return_items = [];
      }
      vm.$store.dispatch('page_load', false);
    },
    /* 取消訂單 */
    async cancel_order(orderSerial) {
      const vm = this;
      const value = await vm.$swal({
        title: '確定取消此訂單?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: '關閉',
        confirmButtonText: '確定',
      });
      if (value.isConfirmed) {
        vm.$store.dispatch('page_load', true);
        const response = await API_CANCEL_ORDER(orderSerial);
        const res = response.data;
        // console.log('取消訂單 成功', response);
        if (res.code === '0000') {
          await vm.get_member_detail(vm.member.userId);
          vm.$swal({
            icon: 'success',
            title: '成功',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
        }
        vm.$store.dispatch('page_load', false);
      }
    },
    /* 確認訂單帳款 */
    async confirm_order_item(order, bill) {
      const vm = this;
      const value = await vm.$swal({
        title: '確定確認訂單款項?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        html: `
          <p class="text-left">訂單編號: <span>${order.orderSerial}</span></p>
          <p class="text-left text-danger">訂購人: <span class="font-weight-bold" style="font-size: 22px;">${order.purFullname}</span></p>
          <p class="text-left text-danger">付款方式: <span class="font-weight-bold" style="font-size: 22px;">${bill.paymentName}</span></p>
          <p class="text-left text-danger">金額: <span class="font-weight-bold" style="font-size: 22px;">${bill.amount}</span></p>
          <p class="text-left">回饋碼: <input id="bill_number" class="form-control form_input"></input></p>
          <p class="text-left">備註: <input id="bill_memo" class="form-control form_input"></input></p>
        `,
        confirmButtonColor: '#dc3545',
        confirmButtonText: '確定',
        cancelButtonColor: '#3085d6',
        cancelButtonText: '取消',
      });
      if (value.isConfirmed) {
        vm.$store.dispatch('page_load', true);
        const orderBillConfirm = {
          serialNumber: bill.serialNumber,
          requestNumber: $('#bill_number').val(),
          memo: $('#bill_memo').val(),
        };
        const response = await API_UPDATE_ORDER_BILL(orderBillConfirm);
        const res = response.data;
        // console.log('款項確認 成功', response);
        if (res.code === '0000') {
          await vm.get_member_detail(order.userId);
          vm.order_content = 'list';
          vm.$swal({
            icon: 'success',
            title: '成功',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
        }
        vm.$store.dispatch('page_load', false);
      }
    },
    /* 部分商品退貨 */
    async return_order_item(order) {
      $('#modalReturnItems').modal('hide');
      const vm = this;
      vm.$store.dispatch('page_load', true);
      const returnItemsData = [];
      vm.return_items.forEach((item) => {
        const returnItem = { serialNumber: item.serialNumber, quantity: item.inputQuantity };
        returnItemsData.push(returnItem);
      });
      const orderRuturn = {
        orderSerial: order.orderSerial,
        paymentCode: vm.return_paymentCode,
        requestNumber: vm.return_requestNumber,
        memo: vm.return_memo,
        returnItems: returnItemsData,
      };
      const response = await API_UPDATE_ORDER_RETURN_ITEM(orderRuturn);
      const res = response.data;
      // console.log('款項確認 成功', response);
      if (res.code === '0000') {
        await vm.get_member_detail(order.userId);
        await vm.get_order_detail(order.orderSerial);
        vm.order_content = 'list';
        vm.$swal({
          icon: 'success',
          title: '成功',
          text: res.text,
          confirmButtonText: '關閉視窗',
        });
      }
      vm.$store.dispatch('page_load', false);
    },
    show_modal() {
      $('#modalReturnItems').modal();
    },
    /* 選擇是否退貨 */
    item_check(item, e) {
      const vm = this;
      const itm = {
        serialNumber: item.serialNumber,
        prodName: item.prodName + item.standard,
        quantity: item.quantity - item.returnQuantity,
        inputQuantity: 1,
      };
      if (e.target.checked) {
        vm.return_items.push(itm);
      } else {
        const i = vm.return_items.map((itm) => itm.serialNumber).indexOf(itm.serialNumber);
        vm.return_items.splice(i, 1);
      }
    },
    /* 更新訂單 收件資料 */
    async update_rec() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('edit');
      if (validateResult) {
        const recData = {
          orderSerial: vm.order.orderSerial,
          recFullname: vm.order.rec.recFullname,
          recCelnum: vm.order.rec.recCelnum,
          recTelnum: vm.order.rec.recTelnum,
          recTelnumExt: vm.order.rec.recTelnumExt,
          recAddrCityCode: vm.order.rec.recAddrCityCode,
          recAddrDetail: vm.order.rec.recAddrDetail,
          customDefine3: vm.order.rec.customDefine3,
          customDefine4: vm.order.rec.customDefine4,
          arriveDate: vm.order.arriveDate,
          arriveTime: vm.order.arriveTime,
          delivery: vm.order.delivery,
          deliverySerial: vm.order.deliverySerial,
          memo: vm.order.memoCustomer,
        };
        const response = await API_UPDATE_ORDER(recData);
        const res = response.data;
        // console.log('更新訂單 成功');
        if (res.code === '0000') {
          vm.$swal({
            icon: 'success',
            title: '成功',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
          await vm.get_order_detail(vm.order.orderSerial);
          vm.order_mode = '';
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 更新發票資料 */
    async update_receipt() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('edit');
      if (validateResult) {
        const receiptData = {
          orderSerial: vm.order.orderSerial,
          receiptType: vm.order.receipt.receiptType,
          receiptUbn: vm.order.receipt.receiptUbn,
          receiptTitle: vm.order.receipt.receiptTitle,
          receiptNumber: vm.order.receipt.receiptNumber,
          receiptDate: vm.order.receipt.receiptDate,
          receiptVehicleMobile: vm.order.receipt.receiptVehicleMobile,
        };
        const response = await API_UPDATE_ORDER_RECEIPT(receiptData);
        const res = response.data;
        // console.log('更新訂單 成功');
        if (res.code === '0000') {
          vm.$swal({
            icon: 'success',
            title: '成功',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
          await vm.get_order_detail(vm.order.orderSerial);
          vm.order_mode = '';
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 更新G幣 */
    async update_gpoint(uId) {
      const vm = this;
      const data = {
        userId: uId,
        orderSerial: vm.gpoint.orderSerial,
        paymentCode: vm.gpoint.code,
        pointChange: vm.gpoint.value,
        labelCode: '',
        eventName: vm.gpoint.event,
        eventReason: vm.gpoint.desc,
      };
      const response = await API_UPDATE_MEMBER_GPOINT(data);
      const res = response.data;
      if (res.code === '0000') {
        vm.$swal({
          icon: 'success',
          title: '成功',
          text: res.text,
          confirmButtonText: '關閉視窗',
        });
      } else {
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: res.text,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    async member_register() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('register');
      if (validateResult) {
        const data = {
          fullname: vm.register.fullname,
          gender: vm.register.gender,
          birthDay: vm.register.birthDay,
          email: vm.register.email || '',
          celnum: vm.register.celnum,
          telnum: vm.register.celnum,
          telnumExt: '',
          addrCityCode: vm.register.addrCityCode,
          addrDetail: vm.register.addrDetail,
          password: vm.register.password,
          memo: vm.register.memo || '',
          vehicleMobile: vm.register.vehicleMobile || '',
          optionSendSms: true,
          optionSendEdm: true,
        };
        const response = await API_REGISTER_MEMBER(data);
        const res = response.data;
        if (res.code === '0000') {
          vm.$swal({
            icon: 'success',
            title: '成功',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
          vm.member_content = '';
          vm.register = {};
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    async reset_password() {
      const vm = this;
      const response = await API_RESET_PASSWORD(vm.member.userId);
      const res = response.data;
      if (res.code === '0000') {
        vm.$swal({
          icon: 'success',
          title: '成功!',
          text: res.text,
          confirmButtonText: '關閉視窗',
        });
      }
    },
  },
};
</script>
